
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

const PAGE_SIZE = 20
const scale: number = window.document.documentElement.clientWidth / 375

@Component({
  name: 'memberAccount',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class memberAccount extends Vue {
  @Provide() public list: any = []
  @Provide() public info: any = {}
  private loadMore: boolean = true
  private pageIndex: number = 1
  private showBottom: boolean = false

  get empty () {
    return this.pageIndex === 1 && this.list && this.list.length === 0
  }

  public created () {
    this.refresh()
  }

  private mounted () {
    window.addEventListener('scroll', this.fetchScrollTop)
  }

  private refresh () {
    this.pageIndex = 1
    this.list = []
    this.info = {}
    this.loadMore = true
    this.loadList()
  }

  @Emit()
  private async loadList () {
    if (!this.loadMore) {
      return
    }
    this.loadMore = false
    try {
      const res = await httpHelper.get({
        url: 'IUser/GrowthRecords',
        data: {
          pageIndex: this.pageIndex,
          pageSize: PAGE_SIZE
        },
        type: 'apiv5'
      })
      this.info = res.data
      const result = res.data.record
      this.list = [
        ...this.list,
        ...result
      ]
      this.loadMore = !!result.length
      this.showBottom = result.length < PAGE_SIZE
      if (this.loadMore) {
        this.pageIndex = this.pageIndex + 1
      }
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private goBack () {
    window.history.back()
  }

  @Emit()
  private fetchScrollTop (event: any) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    const cHeight = document.documentElement.clientHeight || document.body.clientHeight
    const sHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    if (sHeight - (scrollTop + cHeight) < scale * 100) {
      this.loadList()
    }
  }
}

